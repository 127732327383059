function replaceGifWithFallback() {
  const elements = document.querySelectorAll('[data-gif-fallback]');
  elements.forEach(element => {
      setTimeout(() => {
        if (element.paused) {
          const img = document.createElement('img');
          img.src = element.dataset.gifFallback;
          img.alt = element.alt;
          img.className = element.className;
          element.replaceWith(img);
        }
      }, 3000);
  });
};

document.addEventListener('DOMContentLoaded', () => {
    replaceGifWithFallback();
});

// demos page

function reframe(link) {
  
  // find any existing iframe and remove it
  const frames = document.querySelectorAll('iframe');
  Array.from(frames).forEach(frame => {
      frame.remove();    
  });

  // create a new iframe with the URL provided
  const url = link.getAttribute('href');
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', url);
  iframe.setAttribute('style', "z-index:10;");
  document.body.prepend(iframe);

  // write contents of data-explainer attribute as html contents of p#blurb
  const explainer = document.querySelector('#blurb');
  explainer.innerHTML = link.dataset.explainer;

  // remove 'active' class from any sibling elements and add it back to link
  const links = document.querySelectorAll('.demo-link');
  Array.from(links).forEach(link => {
    link.classList.remove('active');
  });
  link.classList.add('active');

  return;
}

if (document.body.classList.contains("section-demos")) {
  // first frame is first link in the list
  // find a elements with class "demo-link"
  const links = document.querySelectorAll('.demo-link');
  reframe(links[0]);
}

// get all a.demo-link and intercept clicks on links and reframe the page

const links = document.querySelectorAll('.demo-link');
Array.from(links).forEach(link => {
  link.addEventListener('click', (event) => {
    event.preventDefault();
    reframe(link);
    // uncheck #navopener
    const navopener = document.querySelector('#navopener');
    navopener.checked = false;
  });
});